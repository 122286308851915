.leaflet-tooltip.weather-tooltip {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: none;
}

.divIconEmpty {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: none;
}

.divIconText {
  transform: translate(-50%, -50%);
}
