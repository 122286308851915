.root {
  user-select: none;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.controls-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.content-container {
  overflow-y: scroll;
  height: 100%;
}

.content-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content-row.root {
  background-color: var(--color);
  border-bottom: solid grey 2px;
}
.content-row.root.collapsed {
  justify-content: center;
  height: 50px;
}
.content-row.root.expanded {
  padding-top: 15px;
  height: auto;
}
.content-row.header {
  flex-direction: row;
  cursor: pointer;
  height: 25px;
}
.content-row.section {
  display: flex;
  flex-direction: row;
  flex: 1;
  text-overflow: ellipsis;
  width: 100%;
  align-items: center;
  gap: 15px;
}
.content-row.body {
  padding: 15px;
}
.content-row.body.headline {
  font-size: 18px;
  font-weight: bold;
}
.content-row.body.message {
  padding: 2px;
}
.content-row.body.info-grid {
  display: grid;
  gap: 15px;
  grid-template-columns: var(--grid-size);
  grid-template-rows: var(--grid-size);
  align-items: center;
}
.content-row.body.slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  padding: 0px;
}
.content-row.body.slider-text {
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 5px;
}

.icon-back-circle {
  background-color: var(--color);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: solid white 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-parent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  align-items: center;
  gap: 15px;
}
.control-parent.no-width {
  width: auto;
}

.full-width {
  width: 100%;
}

.title {
  width: 100%;
  font-size: 18px;
}

.margin-horizontal {
  margin-left: var(--margin);
  margin-right: var(--margin);
}

.searchbox {
  width: 150px;
  height: 30px;
  background-color: var(--background-color);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 2px;
}

.selector {
  background-color: #436c9a;
}
